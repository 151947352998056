import {useEffect, useState} from 'react';

// components
import Loader from '../../components/Loader';
import Dropdown from '../../components/Dropdown';
import MultiStepHeader from '../../components/MultiStepHeader';

// services
import {api} from '../../services/api';
import {trans} from '../../services/lang';

// hooks
import {useSettings} from '../../hooks/useSettings';
import {useQuestionnaire} from '../../hooks/useQuestionnaire';

// styles
import './index.scss';

type Props = {
  setShowPersonalInfo: Function
}

const PersonalInfo = (props: Props) => {
  const { language } = useSettings();
  const { externalTestId } = useQuestionnaire();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [household, setHousehold] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (age && household && gender) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [gender, age, household]);

  const handleClick = () => {
    setSubmitDisabled(true);
    setIsSaving(true);
    const data = {
      age,
      sex: gender,
      householdMembers: household,
      externalTestId: externalTestId,
      timezoneOffset: new Date().getTimezoneOffset() * 60,
    }

    api.savePersonalInfo(data)
      .then(() => {
        setIsSaving(false);
        props.setShowPersonalInfo(false);
      });
  };

  return (
    <div id="extra_info">
      <MultiStepHeader step={1} />

      <div id="extra_info-wrapper">
        {
          isSaving
            ? <Loader/>
            : (
              <div id='extra_info-container'>
                <Dropdown
                  label={trans(language, 'Gender')}
                  options={[
                    {value: '', label: trans(language, 'Please choose an option')},
                    {value: 'F', label: trans(language, 'Female')},
                    {value: 'M', label: trans(language, 'Male')}
                  ]}
                  setValue={setGender}
                  elementId='gender-select'
                  name='gender'
                />

                <Dropdown
                  label={trans(language, 'How old are you?')}
                  options={[
                    {value: '', label: trans(language, 'Please choose an option')},
                    {value: '18-22', label: '18-22'},
                    {value: '23-30', label: '23-30'},
                    {value: '31-45', label: '31-45'},
                    {value: '46-55', label: '46-55'},
                    {value: '56-65', label: '56-65'},
                    {value: '66+', label: '66+'}
                  ]}
                  setValue={setAge}
                  elementId='age-select'
                  name='age'
                />

                <Dropdown
                  label={trans(language, 'How many people live in your home?')}
                  options={[
                    {value: '', label: trans(language, 'Please choose an option')},
                    {value: '1', label: '1'},
                    {value: '2', label: '2'},
                    {value: '3', label: '3'},
                    {value: '4', label: '4'},
                    {value: '5', label: '5'},
                    {value: '6+', label: '6+'}
                  ]}
                  setValue={setHousehold}
                  elementId='household-select'
                  name='household'
                />

                <button onClick={handleClick} disabled={submitDisabled}>
                  {trans(language, 'Continue')}
                </button>
              </div>
            )
        }
      </div>
    </div>
  );
};

export default PersonalInfo;
