import React from "react";
import { minutesAndSecondsFormatter } from "../../../../utils/time";

interface Props {
  seconds: number
}

const TimeLeft = ({
  seconds,
}: Props) => {
  return (
    <div id="time-left">
      <p className={`${seconds < 10 ? "red-text" : ""}`}>
        { minutesAndSecondsFormatter(seconds) }
      </p>
    </div>
  );
};

export default TimeLeft;
