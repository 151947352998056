import React, {useContext, useState, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// services
import {api} from '../services/api';

// actions
import {setQuestions} from '../state/actions/questionsActions';

// types
import QuestionnaireType from '../types/questionnaire.entity';
import QuestionType from '../types/question.entity';

interface Props {
  questions: QuestionType[];
  answeredQuestions: QuestionType[];
  unansweredQuestions: QuestionType[];
  questionnaire: QuestionnaireType;
  hasMoreQuestions: boolean;
  externalTestId: string;
  submissionId: string;
  domain?: string;
  isQuestionnaireCreated: boolean;
  isQuestionnaireViewed: boolean;
  isQuestionnaireStarted: boolean;
  isQuestionnaireCompleted: boolean;
  isQuestionnaireExpired: boolean;
  isQuestionnaireNotFound: boolean;
  isQuestionnaireLoaded: boolean;
  hasTimedBoxedQuestions: boolean;
  isExpiredOnLoad: boolean;
  isAnonymised: boolean;
  errorMessage: string;
}

const QuestionnaireContext = React.createContext<Props | null>(null);

export const QuestionnaireContextProvider = ({ children }: any) => {
  const dispatch = useDispatch();
  const [externalTestId, setExternalTestId] = useState<string>('');
  const [isExpiredOnLoad, setIsExpiredOnLoad] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { questionnaire, answeredQuestions, unansweredQuestions } = useSelector(
    (state: any) => state.questionsReducer
  )

  const isQuestionnaireStarted = useMemo(() => (
    questionnaire?.test_status === 'started'
  ), [questionnaire?.external_test_id, questionnaire?.test_status]);

  const isQuestionnaireCompleted = useMemo(() => (
    questionnaire?.test_status === 'completed'
  ), [questionnaire?.external_test_id, questionnaire?.test_status]);

  const isQuestionnaireCreated = useMemo(() => (
    questionnaire?.test_status === 'created'
  ), [questionnaire?.external_test_id, questionnaire?.test_status]);

  const isQuestionnaireViewed = useMemo(() => (
    questionnaire?.test_status === 'viewed'
  ), [questionnaire?.external_test_id, questionnaire?.test_status]);

  const isQuestionnaireExpired = useMemo(() => (
    questionnaire?.test_status === 'expired' || questionnaire?.test_status === 'completed'
  ), [questionnaire?.external_test_id]);

  const isQuestionnaireNotFound = useMemo(() => (
    questionnaire?.test_status === 'not_found'
  ), [questionnaire?.external_test_id, questionnaire?.test_status]);

  const isQuestionnaireLoaded = useMemo(() => !!questionnaire, [questionnaire]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isAnonymised = useMemo(() => !!questionnaire?.is_anonymised || !!questionnaire?.answers?.age, [questionnaire?.external_test_id]);

  const hasTimedBoxedQuestions = useMemo(() => (
    !!questionnaire?.questions.filter(({ type }: any) => type !== 'video').length
  ), [questionnaire?.questions]);

  const hasMoreQuestions = useMemo(() => !!unansweredQuestions?.length, [unansweredQuestions]);

  const submissionId = useMemo(() => questionnaire?.answers.submission_id, [questionnaire?.answers]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const externalTestId = queryParams.get("id");

    if (externalTestId) {
      setExternalTestId(externalTestId || "");

      if (questionnaire?.external_test_id !== externalTestId) {
        api.getQuestionnaire(externalTestId)
          .then((data) => {
            if (data.success === false) {
              return setErrorMessage(data.message)
            }
            dispatch(setQuestions(data));

            if (data.test_status === 'expired' || data.test_status === 'completed') {
                setIsExpiredOnLoad(true);
            }
          })
            .catch((error) => {
              console.error(error);
            })
      }

      if (externalTestId) {
        api.getDeviceIdFromTestId(externalTestId)
          .then((data) => {
            localStorage.setItem("deviceId", data.deviceId);
          });
      }
    }
  }, []);

  return (
    <QuestionnaireContext.Provider value={{
      questions: questionnaire?.questions,
      hasMoreQuestions,
      answeredQuestions,
      unansweredQuestions,
      questionnaire,
      externalTestId,
      isQuestionnaireCreated,
      isQuestionnaireViewed,
      isQuestionnaireStarted,
      isQuestionnaireCompleted,
      isQuestionnaireExpired,
      isQuestionnaireNotFound,
      isQuestionnaireLoaded,
      hasTimedBoxedQuestions,
      isExpiredOnLoad,
      isAnonymised,
      errorMessage,
      submissionId,
    }}>
      { children }
    </QuestionnaireContext.Provider>
  );
}

export function useQuestionnaire() {
  const context = useContext(QuestionnaireContext);

  if (!context) {
    throw new Error(
      'useQuestionnaire must be used within a QuestionnaireContextProvider'
    );
  }

  return context;
}
