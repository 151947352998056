// styles
import './index.scss';

type Props = {
  className?: string;
};

const Loader = (props: Props) => (
  <div className={`loading-screen ${props.className}`}>
    <div className="spinner">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  </div>
);

export default Loader;
