import React, {useContext, useMemo} from 'react';
import {useSelector} from 'react-redux';

// types
import QuestionType from "../types/question.entity";

interface Props {
  question: QuestionType;
  isVideoRecordingQuestion: boolean;
  isPercentageQuestion: boolean;
  isBooleanQuestion: boolean;
  isMultipleChoiceQuestion: boolean;
}

const QuestionContext = React.createContext<Props | null>(null);

export const QuestionContextProvider = ({ children }: any) => {
  const question = useSelector(
    (state: any): QuestionType => state.questionsReducer.currentQuestion
  );
  const isPercentageQuestion = useMemo(() => question?.type === "percentage", [question]);
  const isBooleanQuestion = useMemo(() => question?.type === "boolean", [question]);
  const isMultipleChoiceQuestion = useMemo(() => question?.type === "multiple_choice", [question]);
  const isVideoRecordingQuestion = useMemo(() => question?.type === "video", [question]);

  return (
    <QuestionContext.Provider value={{
      question,
      isVideoRecordingQuestion,
      isPercentageQuestion,
      isBooleanQuestion,
      isMultipleChoiceQuestion,
    }}>
      { children }
    </QuestionContext.Provider>
  );
}

export function useQuestion() {
  const context = useContext(QuestionContext);

  if (!context) {
    throw new Error(
      'useQuestion must be used within a QuestionContextProvider'
    );
  }

  return context;
}
