import React, { useState } from 'react';

// styles
import './index.scss';

interface Options {
  [key: string]: string
}

type Props = {
  label: string,
  options: Options[],
  setValue: Function,
  elementId: string,
  name: string
}

const Dropdown = ({
  label,
  options,
  setValue,
  elementId,
  name,
}: Props) => {
  const [defaultValue, setDefaultValue] = useState(options?.[0]?.value);
  const [defaultOption, setDefaultOption] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const {value, options, selectedIndex} = e.target;

    setValue(value);
    setDefaultValue(value);
    setDefaultOption(options[selectedIndex].innerHTML);
  }

  return <>
    <div className='dropdown'>
      <div className="dropdown-label">
        <label htmlFor={elementId}>{label}</label>
      </div>

      <div className='dropdown-wrapper'>
        <span className="dropdown-placeholder">{defaultOption ? defaultOption : options[0].label}</span>

        <select className="dropdown-select" value={defaultValue} name={name} id={elementId} onChange={handleChange}>
          {
            options.map((option, index) => (
              <option key={`${index}${option.label}`} value={option.value}>{option.label}</option>
            ))
          }
        </select>
      </div>
    </div>
  </>
};

export default Dropdown;
