import { createAction } from '@reduxjs/toolkit';

// types
import Question from '../../types/question.entity';
import Questionnaire from '../../types/questionnaire.entity';

// actions
export const setQuestions = createAction<object | undefined>("questions/set");
export const questionFulfilled = createAction<Question>("questions/fulfilled");
export const updateQuestionnaire = createAction<Questionnaire>(
  "questionnaire/update"
);
