import React, { useContext, useState, useEffect } from 'react';
import { messageHost } from "../services/iframe";

interface Props {
  language: string;
  isAppEmbedded: boolean;
  isIframe: boolean
  iframeSrc: string,
  iframeError: string | null;
}

const SettingsContext = React.createContext<Props | null>(null);

export const SettingsContextProvider = ({ children }: any) => {
  const [language, setLanguage] = useState<string>('en');
  const [isAppEmbedded, setIsAppEmbedded] = useState<boolean>(false);
  const [isIframe, setIsIframe ] = useState<boolean>(false);
  const [iframeSrc, setIframeSrc ] = useState<string>('');
  const [iframeError, setIframeError ] = useState<string | null>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isAppEmbedded = !!queryParams.get("app_embedded");
    const language = queryParams.get("l");
    const questionnaireId = queryParams.get("id");
    const isIfrme = window.self !== window.top && window.location !== window.parent.location
    const iframeSrcStr = (isIfrme && queryParams.get("src")) || null;
    const hostOrigin = iframeSrcStr ? new URL(iframeSrcStr).origin : null; // fallback for error messages

    if (language) {
      setLanguage(language);
    }

    if (isAppEmbedded) {
      setIsAppEmbedded(isAppEmbedded);
    }

    // iframe integration validation & init
    if (isIfrme) {
      setIsIframe(isIfrme);

      if(!questionnaireId) {
        setIframeError('Initialisation Failed');
        messageHost('initialisation-failed', '*');
      } else {
        setIframeSrc(`${hostOrigin}/`);
      }
    }
  }, []);

  return (
    <SettingsContext.Provider value={{
      language,
      isAppEmbedded,
      isIframe,
      iframeSrc,
      iframeError,
    }}>
      { children }
    </SettingsContext.Provider>
  );
}

export function useSettings() {
  const context = useContext(SettingsContext);

  if (!context) {
    throw new Error(
      'useSettings must be used within a SettingsContextProvider'
    );
  }

  return context;
}
