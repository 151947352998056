import {useState, useMemo} from 'react';

// utils
import {toHoursMinutesSeconds} from '../../../../utils/time'

// services
import {trans} from '../../../../services/lang';

// hooks
import {useSettings} from '../../../../hooks/useSettings';
import {useQuestionnaire} from '../../../../hooks/useQuestionnaire';

// styles
import './index.scss';

type Props = {
  startTest: Function,
}

const Intro = ({
  startTest,
}: Props) => {
  const { language } = useSettings();
  const { questionnaire } = useQuestionnaire();

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const {
    numberOfQuestions,
    questionsDurationSeconds,
  } = useMemo(() => {
    const seconds = questionnaire?.questions?.reduce((reduced: any, {max_answering_time}: any) => reduced + max_answering_time, 0);
    const {m, s} = toHoursMinutesSeconds(seconds);

    return {
      numberOfQuestions: questionnaire?.questions?.length,
      questionsDurationSeconds: s,
      questionsDurationMinutes: m,
    };
  }, [questionnaire?.questions]);

  const handleClick = async () => {
    setIsSubmitDisabled(true);
    return startTest();
  };

  return (
    <div id='intro-container'>
      <h2>{trans(language, 'Instructions')}</h2>

      <p className='intro-text'>
        {
          numberOfQuestions > 1 ? (
            <>
              {
                questionnaire.domain === 'upscoreapp' ? (
                  <>
                    <p>
                      {
                        trans(language, 'Service providers would like to get to know you better so that they can customise offers for you.')
                      }
                    </p>

                    <p>
                      {
                        trans(language, 'We will ask you {{NUMBER_OF_QUESTIONS}} questions.').replace('{{NUMBER_OF_QUESTIONS}}', String(numberOfQuestions))
                      }
                    </p>
                  </>
                ) : (
                  <p>
                    {
                      trans(language, 'We will ask you {{NUMBER_OF_QUESTIONS}} questions. After each question you will have a few seconds to record your answer. Please use all of the time given for each answer.').replace('{{NUMBER_OF_QUESTIONS}}', String(numberOfQuestions))
                    }
                  </p>
                )
              }
            </>
          ) : (
            <span>
              {
                trans(language, 'We will ask you a question and you will then have {{SECONDS_TO_RESPOND}} seconds to record your answer.').replace('{{SECONDS_TO_RESPOND}}', String(questionsDurationSeconds))
              }
            </span>
          )
        }
      </p>

      <button id='intro-start-button' onClick={handleClick} disabled={isSubmitDisabled}>
        {trans(language, 'Get started ')}
      </button>
    </div>
  );
};

export default Intro;
