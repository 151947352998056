const wait = async (ms: number) => new Promise((res) => setTimeout(res, ms));

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string;

interface RequestOptions {
    timeToWaitNext?: number,
    queryParams?: string
}

export const api = {
  retryApi: async (
    apiRequest: any,
    onSucess: (...args: any[]) => void = () => {
    },
    opts?: RequestOptions,
  ) => {
    const queryParams = opts?.queryParams
    let timeToWaitNext = opts?.timeToWaitNext ?? 5000

    try {
      const response = await apiRequest(queryParams? `?${queryParams}` : '');
      if (response.ok) {
        const data = await response.json();
        onSucess?.(data);
        return;
      }
      await wait(timeToWaitNext);
      timeToWaitNext = timeToWaitNext * 2;
      api.retryApi(apiRequest, onSucess, { ...opts, timeToWaitNext });
    } catch (error) {
      await wait(timeToWaitNext);
      timeToWaitNext = timeToWaitNext * 2;
      api.retryApi(apiRequest, onSucess, { ...opts, timeToWaitNext });
    }
  },

  createTest: async (i: string | null) => {
    const response = await fetch(
      `${API_BASE_URL}/v1/test`,
      {
        method: "POST",
        body: JSON.stringify({
          source_id: i,
        }),
        headers: {
          'Content-Type': 'application/json'
        },
      }
    );
    return response.json();
  },

  startTest: async (data: any) => {
    const response = await fetch(
      `${API_BASE_URL}/v1/test/start`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        },
      }
    );
    return response.json();
  },

  completeTest: async (externalTestId: string) => {
    const response = await fetch(
      `${API_BASE_URL}/v1/test/${externalTestId}/complete`,
      {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          'Content-Type': 'application/json'
        },
      }
    );
    return response.json();
  },

  getQuestionnaire: async (id: string | null) => {
    const response = await fetch(
      `${API_BASE_URL}/v1/test/${id}/questionnaire`
    );

    return response.json();
  },

  saveAnswers: async (id: string, answers: any[]) => {
    return fetch(
      `${API_BASE_URL}/v1/test/${id}/answers`,
      {
        method: "POST",
        body: JSON.stringify(answers),
        headers: {
          'Content-Type': 'application/json'
        },
      }
    );
  },

  createSignedS3VideoPartUrl: async (queryParamsStr: string = '') => {
    return fetch(
      `${API_BASE_URL}/v1/s3/video/part/signed-url${queryParamsStr}`,
      {
        method: "GET",
      }
    );
  },

  uploadVideo: async (signedUrl: any, videoBlob: any) => {
    await fetch(
      signedUrl,
      {
        method: "PUT",
        body: videoBlob,
      }
    );

    return;
  },

  saveExtraInfo: async (data: any) => {
    await fetch(
      `${API_BASE_URL}/v1/test/${data.externalTestId}/extra-info`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        },
      }
    );

    return;
  },

  savePersonalInfo: async (data: any) => {
    await fetch(
      `${API_BASE_URL}/v1/test/${data.externalTestId}/personal-info`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        },
      }
    );

    return;
  },

  getDeviceIdFromTestId: async (testId: string | null) => {
    const response = await fetch(
      `${API_BASE_URL}/v1/test/${testId}/device-id`
    );
    return response.json();
  },
};
