// services
import { videoReviewUrl } from '../../../../services/videoReview';
import { trans } from '../../../../services/lang';

// hooks
import {useSettings} from "../../../../hooks/useSettings";

// styles
import './index.scss';

const Expired = () => {
  const { language } = useSettings();

  const handleGoToVideoReviewClick = () => {
    if (process.env.REACT_APP_ENABLE_MICROWORKER !== 'true') return;
    (window as any).ReactNativeWebView.postMessage(JSON.stringify({externalUrl: videoReviewUrl(localStorage.getItem('deviceId') ?? '')}));
  }

  const displayMicroworkerButton = process.env.REACT_APP_ENABLE_MICROWORKER === 'true';
  const reviewButtonClassNames = `video-review-site-button ${process.env.REACT_APP_ENABLE_MICROWORKER === 'true' ? 'visible' : ''}`;
  const hasDeviceId = !!localStorage.getItem('deviceId');

  return (
    <div id="questionnaire-expired-container">
      <p>
        {trans(language, 'Questionnaire expired')}
      </p>

      {
        displayMicroworkerButton && hasDeviceId && (
          <div>
            <hr/>
            <p>{trans(language, 'A Good Opportunity!')}</p>
            <p>{trans(language, 'Earn money labelling videos in our app.')}</p>
          </div>
        )
      }

      {
        hasDeviceId && (
          <button
            className={reviewButtonClassNames}
            onClick={handleGoToVideoReviewClick}
          >
            {trans(language, 'Review Videos')}
          </button>
        )
      }
    </div>
  )
};

export default Expired;
