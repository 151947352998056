import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import QuestionType from '../../types/question.entity';
import Questionnaire from '../../types/questionnaire.entity';
import {
  setQuestions,
  questionFulfilled,
  updateQuestionnaire,
} from '../actions/questionsActions';

export interface QuestionsState {
  questionnaire?: Questionnaire;
  currentQuestion?: QuestionType;
  answeredQuestions?: QuestionType[];
  unansweredQuestions?: QuestionType[];
}

const initialState: QuestionsState = {
  questionnaire: undefined,
  currentQuestion: undefined,
  answeredQuestions: undefined,
  unansweredQuestions: undefined,
};

const handleQuestionsInit = (
  state: QuestionsState,
  { payload }: PayloadAction<Questionnaire>
) => {
  state.questionnaire = payload;
  state.currentQuestion = payload.unanswered[0];
  state.answeredQuestions = payload.answered;
  state.unansweredQuestions = payload.unanswered;
};

const handleUpdateQuestionnaire = (
  state: QuestionsState,
  { payload }: PayloadAction<Questionnaire>
) => {
  state.questionnaire = payload;
};

const handleQuestionFulfilled = (
  state: QuestionsState,
  { payload }: PayloadAction<QuestionType>
) => {
  if (state.questionnaire) {
    const index = state.questionnaire.questions.findIndex(
      (q: any) => q.id === payload.id
    );
    const newQuestions = [...state.questionnaire.questions];
    newQuestions[index] = payload;

    state.questionnaire = { ...state.questionnaire, questions: newQuestions };
    state.answeredQuestions = [...((state.answeredQuestions as any) || []), state.currentQuestion];
    state.unansweredQuestions?.shift();
    state.currentQuestion = state.unansweredQuestions?.[0];

    const progress = Math.round((state.answeredQuestions.length / state.questionnaire.questions.length) * 100);

    state.questionnaire = { ...state.questionnaire, progress };
  }
};

export default createReducer(initialState, {
  [setQuestions.type]: handleQuestionsInit,
  [questionFulfilled.type]: handleQuestionFulfilled,
  [updateQuestionnaire.type]: handleUpdateQuestionnaire,
});
