import React, {useRef} from 'react';

// styles
import './index.scss';

interface VideoProps extends React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> {
}


/**
 * onAbort, onCanPlay, onCanPlayThrough, onDurationChange, onEmptied,
 * onEncrypted, onEnded, onError, onLoadedData, onLoadedMetadata, onLoadStart,
 * onPause, onPlay, onPlaying, onProgress, onRateChange, onSeeked, onSeeking,
 * onStalled, onSuspend, onTimeUpdate, onVolumeChange, onWaiting.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const VideoPLayer = ({src, ...props}: VideoProps) => {
  const videoRef = useRef<any>(null!)

  return (
    <div id="video-wrapper">
      <video
        ref={videoRef}
        autoPlay
        controls
        {...props}
      >
        Sorry, your browser doesn't support embedded videos
        <source src={src}/>
      </video>
    </div>
  );
};

export default VideoPLayer;
