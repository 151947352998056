import styled from "styled-components";

// services
// import {trans} from '../../services/lang';

const ErrorWrapper = styled.div`
  height: 100vh;
  width: 80%;
  max-width: 80%;
  

  pre {
    overflow-x: auto;
    overflow-y: scroll;
    text-align: center;
    white-space: pre-wrap;

    margin-top: 30vh;
    padding: 0 2rem;
    color: #de2b2b;
    font-size: 1.2rem;
  }
`

type Props = {
  message: string,
}

const ErrorPanel = ({ message }: Props) => {
  return (
    <ErrorWrapper>
      <pre>❗{message}</pre>
    </ErrorWrapper>
  );
};

export default ErrorPanel;
