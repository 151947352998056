interface Translation {
    [key: string]: string
}

interface Dictionary {
    [key: string]: Translation
}

export const trans = (language: string, string: string) => {
    const dictionary : Dictionary = {
        'en': {
            'True': 'True',
            'False': 'False',
            'Your score': 'Your score',
            'Go to Lender’s Site': 'Go to Lender’s Site',
            'Questionnaire completed': 'Questionnaire completed',
            'Questionnaire expired': 'Questionnaire expired.',
            'Instructions': 'Instructions',
            'We help you access credit on more attractive terms': 'We help you access credit on more attractive terms',
            'Instructions_text_web1': 'How? We provide an alternative credit scoring system that improves your chances of securing the loan you need. To help us do that, we need to get to know you better by asking some questions as well as getting permission to access some of your data.',
            'Instructions_text_web2': 'Please answer the questions as quickly as possible, before the timer runs out.',
            'Instructions_text': 'Completing this questionnaire will take approximately 2 minutes. Please make sure you can complete the questionnaire without interruptions.',
            'Start': 'Start',
            'Continue': 'Continue',
            'How familiar you are with': 'How familiar you are with',
            'I have heard of it many times': 'I have heard of it many times',
            'I have heard of it sometimes': 'I have heard of it sometimes',
            'I have never heard of it': 'I have never heard of it',
            'Questionnaire not found.': 'Questionnaire not found.',
            'PRESS': 'PRESS',
            'REC': 'REC',
            'WHEN READY': 'WHEN READY',
            'Use another video': 'Use another video',
            'Upload a video': 'Upload a video',
            'Record a video': 'Record a video',
            'Turn my camera ON': 'Turn my camera ON',
            'Completely': 'Completely',
            'disagree': 'disagree',
            'agree': 'agree',
            'Next': 'Next',
            'Please record a 10 second video explaining why you deserve a loan': 'Please record a 10 second video explaining why you deserve a loan',
            'Please record a 10 second video explaining why you deserve a property': 'Please record a 10 second video explaining why you deserve a property',
            'You can only check your score once every 30 days': 'You can only check your score once every 30 days',
            'Review Videos': 'Review Videos',
            'Gender': 'Gender',
            'How old are you?': 'How old are you?',
            'How many people live in your home?': 'How many people live in your home?',
            'Male': 'Male',
            'Female': 'Female',
            'Please choose an option': 'Please choose an option',
            'Thank you for answering, we will be in touch soon.': 'Thank you for answering, we will be in touch soon.',
            'A Good Opportunity!': 'A Good Opportunity!',
            'Earn money labelling videos in our app.': 'Earn money labelling videos in our app.',
            'We are processing your details. It can take up to 20 seconds.': 'We are processing your details. It can take up to 20 seconds.',
            'You have a bad internet connection. Please try to improve it if possible.': 'You have a bad internet connection. Please try to improve it if possible.',
            'Capture selfie': 'Capture selfie',
            'Delete': 'Delete',
            'Sending...': 'Sending...',
            'Please take a selfie making sure your face is completely in the indicated area.': 'Please take a selfie making sure your face is completely in the indicated area.',
            'Step': 'Step',
            'We will ask you a question and you will then have {{SECONDS_TO_RESPOND}} seconds to record your answer.': 'We will ask you a question and you will then have {{SECONDS_TO_RESPOND}} seconds to record your answer.',
            'We will ask you {{NUMBER_OF_QUESTIONS}} questions. After each question you will have a few seconds to record your answer. Please use all of the time given for each answer.': 'We will ask you {{NUMBER_OF_QUESTIONS}} questions. After each question you will have a few seconds to record your answer. Please use all of the time given for each answer.',
            'We will ask you {{NUMBER_OF_QUESTIONS}} questions.': 'We will ask you {{NUMBER_OF_QUESTIONS}} questions.',
            'Service providers would like to get to know you better so that they can customise offers for you.': 'Service providers would like to get to know you better so that they can customise offers for you.',
            'Attention': 'Attention',
            'Please ensure that no other application is using your camera and microphone & you have granted access to your camera and microphone to your browser ({{browser}}) in your system settings. Then reload the page.': 'Please ensure that no other application is using your camera and microphone & you have granted access to your camera and microphone to your browser ({{browser}}) in your system settings. Then reload the page.',
            'Please reset your microphone and camera permissions in your browser settings': 'Please reset your microphone and camera permissions in your browser settings',
        },
        'es': {
            'True': 'Verdadero',
            'False': 'Falso',
            'Your score': 'Tu score es',
            'Go to Lender’s Site': 'Ir al sitio del prestamista',
            'Questionnaire completed': 'El cuestionario ha sido completado',
            'Questionnaire expired': 'El cuestionario ha expirado',
            'Instructions': 'Instrucciones',
            'We help you access credit on more attractive terms': 'Te ayudamos a acceder a créditos en mejores términos',
            'Instructions_text_web1': '¿Cómo? ofrecemos un análisis crediticio alternativo que mejora tus posibilidades de acceder a préstamos. Para ayudarnos con ello, necesitamos hacerte algunas preguntas y recopilar algunos datos para conocerte mejor.',
            'Instructions_text_web2': 'Por favor responde a las preguntas lo más rápido posible, antes de que se termine el tiempo.',
            'Instructions_text': 'Completar este formulario te requiere 2 minutos. Debes completarlo sin interrupciones.',
            'Start': 'Empezar',
            'Continue': 'Continuar',
            'How familiar you are with': 'Cuán familiar estas con',
            'I have heard of it many times': 'Lo he escuchado muchas veces',
            'I have heard of it sometimes': 'Lo he escuchado alguna vez',
            'I have never heard of it': 'Nunca lo he escuchado',
            'Questionnaire not found.': 'El cuestionario no ha sido encontrado.',
            'PRESS': 'PRESIONE',
            'REC': 'GRABAR',
            'WHEN READY': 'CUANDO QUIERA',
            'Use another video': 'Use otro video',
            'Upload a video': 'Subir un video',
            'Record a video': 'Grabar un video',
            'Turn my camera ON': 'Encender cámara',
            'Completely': 'En total',
            'disagree': 'desacuerdo',
            'agree': 'acuerdo',
            'Next': 'Siguiente',
            'Please record a 10 second video explaining why you deserve a loan': 'Por favor graba un video de 10 segundos explicando por qué te mereces un préstamo',
            'Please record a 10 second video explaining why you deserve a property': 'Por favor graba un video de 10 segundos explicando por qué te mereces un préstamo',
            'You can only check your score once every 30 days': 'Solo puedes consultar tu score una vez cada 30 días.',
            'Review Videos': 'Clasifica Videos',
            'Gender': 'Sexo (Hombre/Mujer)',
            'How old are you?': 'Edad',
            'How many people live in your home?': '¿Cuántas personas viven en tu hogar?',
            'Male': 'Hombre',
            'Female': 'Mujer',
            'Please choose an option': 'Por favor escoje una opción',
            'Thank you for answering, we will be in touch soon.': 'Gracias por contestar, en breve nos comunicaremos contigo.',
            'A Good Opportunity!': '¡Novedad!',
            'Earn money labelling videos in our app.': 'Puedes ganar dinero clasificando videos en nuestra app.',
            'We are processing your details. It can take up to 20 seconds.': 'Estamos procesando tus datos. Puede demorar hasta 20 segundos.',
            'You have a bad internet connection. Please try to improve it if possible.': 'Tienes mala conexión de internet. Intenta mejorarla si puedes.',
            'Capture selfie': 'Capturar selfie',
            'Delete': 'Borrar',
            'Sending...': 'Enviando...',
            'Please take a selfie making sure your face is completely in the indicated area.': 'Por favor, tómate una selfie asegurándote de que tu cara está completamente en el área indicada.',
            'Step': 'Paso',
            'We will ask you a question and you will then have {{SECONDS_TO_RESPOND}} seconds to record your answer.': 'Te haremos una pregunta y luego tendrás {{SECONDS_TO_RESPOND}} segundos para grabar tu respuesta.',
            'We will ask you {{NUMBER_OF_QUESTIONS}} questions. After each question you will have a few seconds to record your answer. Please use all of the time given for each answer.': 'Te haremos {{NUMBER_OF_QUESTIONS}} preguntas. Después de cada pregunta, tendrás unos segundos para grabar tu respuesta. Por favor utiliza todo el tiempo asignado para cada respuesta.',
            'We will ask you {{NUMBER_OF_QUESTIONS}} questions.': 'We will ask you {{NUMBER_OF_QUESTIONS}} questions.',
            'Service providers would like to get to know you better so that they can customise offers for you.': 'Service providers would like to get to know you better so that they can customise offers for you.',
            'Attention': 'Atención',
            'Please ensure that no other application is using your camera and microphone & you have granted access to your camera and microphone to your browser ({{browser}}) in your system settings. Then reload the page.': 'Por favor, asegúrate que ninguna otra aplicación esté utilizando tu cámara y micrófono, y que has otorgado acceso a tu cámara y micrófono a tu navegador ({{browser}}) en la configuración de tu sistema. Luego, actualiza tu página.',
            'Please reset your microphone and camera permissions in your browser settings': 'Por favor, otorga los permisos de tu micrófono y cámara en la configuración de tu navegador.',
        },
        'pt': {
            'True': 'Verdadeiro',
            'False': 'Falso',
            'Your score': 'A tua pontuação',
            'Go to Lender’s Site': 'Vá ao site do credor',
            'Questionnaire completed': 'Questionário Completo',
            'Questionnaire expired': 'Questionário Expirado',
            'Instructions': 'Instruções',
            'We help you access credit on more attractive terms': 'Nós concedemos crédito a taxas competitivas',
            'Instructions_text_web1': 'Como? Nós disponibilizamos uma forma alternativa ao sistema de crédito bancário tradicional, facilitando a forma que voçê cosegue o seu crédito. Para te ajudarmos, nós precisamos fazer algumas perguntas e pedir acesso a alguns dos seus dados.',
            'Instructions_text_web2': 'Por favor responda às questões o mais rápido possível antes de o tempo chegar ao fim.',
            'Instructions_text': 'Responder a este questionário demorará 2 minutos. Por favor tente completar o questionário sem interrupções.',
            'Start': 'Começar',
            'Continue': 'Prosseguir',
            'How familiar you are with': 'Quão familiarizado voçê está',
            'I have heard of it many times': 'Ouvi falar muitas vezes',
            'I have heard of it sometimes': 'Ouvi falar algumas vezes',
            'I have never heard of it': 'Nunca ouvi falar',
            'Questionnaire not found.': 'Questionário não encontrado.',
            'PRESS': 'PRESSIONE',
            'REC': 'GRAVAR',
            'WHEN READY': 'QUANDO QUISER',
            'Use another video': 'Use outro vídeo',
            'Upload a video': 'Envie um vídeo',
            'Record a video': 'Gravar um vídeo',
            'Turn my camera ON': 'Ligar a cámara',
            'Completely': 'Completamente',
            'disagree': 'discordo',
            'agree': 'concordo',
            'Next': 'Próximo',
            'Please record a 10 second video explaining why you deserve a loan': 'Por favor grave um vídeo com 10 segundos de duração nos explicando o porquê de voçê merecer este empréstimo!',
            'Please record a 10 second video explaining why you deserve a property': 'Por favor grave um vídeo com 10 segundos de duração nos explicando o porquê de voçê merecer este empréstimo!',
            'You can only check your score once every 30 days': 'Você só pode verificar sua pontuação uma vez a cada 30 dias.',
            'Review Videos': 'Revisar Vídeos',
            'Gender': 'Sexo (Masculino/Feminino)',
            'How old are you?': 'Era',
            'How many people live in your home?': 'Quantas pessoas moram na sua casa?',
            'Male': 'Masculino',
            'Female': 'Feminino',
            'Please choose an option': 'Por favor escolha uma opção',
            'Thank you for answering, we will be in touch soon.': 'Obrigado por responder, em breve entraremos em contato.',
            'A Good Opportunity!': 'Oportunidade!',
            'Earn money labelling videos in our app.': 'Ganhe dinheiro rotulando vídeos em nosso aplicativo. Iniciar.',
            'We are processing your details. It can take up to 20 seconds.': 'Estamos processando seus dados. Pode levar até 20 segundos.',
            'You have a bad internet connection. Please try to improve it if possible.': 'Você tem uma conexão de internet ruim. Por favor, tente melhorá-lo, se possível.',
            'Capture selfie': 'Capturar selfie',
            'Delete': 'Excluir',
            'Sending...': 'Enviando...',
            'Please take a selfie making sure your face is completely in the indicated area.': 'Por favor, tire uma selfie certificando-se de que seu rosto esteja completamente na área indicada.',
            'Step': 'Etapa',
            'We will ask you a question and you will then have {{SECONDS_TO_RESPOND}} seconds to record your answer.': 'Faremos uma pergunta e você terá {{SECONDS_TO_RESPOND}} segundos para registrar sua resposta.',
            'We will ask you {{NUMBER_OF_QUESTIONS}} questions. After each question you will have a few seconds to record your answer. Please use all of the time given for each answer.': 'Faremos {{NUMBER_OF_QUESTIONS}} perguntas. Após cada pergunta, você terá alguns segundos para gravar sua resposta. Por favor, use todo o tempo dado para cada resposta.',
            'We will ask you {{NUMBER_OF_QUESTIONS}} questions.': 'We will ask you {{NUMBER_OF_QUESTIONS}} questions.',
            'Service providers would like to get to know you better so that they can customise offers for you.': 'Service providers would like to get to know you better so that they can customise offers for you.',
            'Attention': 'Atenção',
            'Please ensure that no other application is using your camera and microphone & you have granted access to your camera and microphone for your browser ({{browser}}) in your system settings. Then reload the page.': 'Por favor, certifique-se de que nenhum outro aplicativo esteja usando sua câmera e microfone e que você concedeu acesso à sua câmera e microfone ao seu navegador ({{navegador}}) nas configurações do seu sistema. Em seguida, recarregue a página.',
            'Please reset your microphone and camera permissions in your browser settings': 'Por favor, redefina as permissões do seu microfone e câmera nas configurações do seu navegador.',
        }
    };

    return dictionary[language][string] || string
}
