import React, { useState } from 'react';

// services
import { trans } from '../../../../services/lang';

// hooks
import {useSettings} from '../../../../hooks/useSettings';
import {useQuestion} from "../../../../hooks/useQuestion";

// styles
import './index.scss';

type Props = {
  saveAnswer: Function;
};

const Percentage = ({
  saveAnswer
}: Props) => {
  const { language } = useSettings();
  const { question } = useQuestion();
  const [answer, setAnswer] = useState(0);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAnswer(parseInt(e.target.value));
  };

  const handleClick = async () => {
    setSubmitDisabled(true);

    await saveAnswer(answer);

    setAnswer(0);
    setSubmitDisabled(false);
  };

  return (
    <>
      <div id="question-text-container">
        <p id="question-text">{question.label}</p>
      </div>

      <div id="answer-slider-container">
        <input
          type="range"
          id="slider"
          min={0}
          max={100}
          value={answer}
          onChange={handleChange}
        />

        <div
          id="slider-filler"
          style={{ width: `calc(${answer}% - ${answer} / 100 * 50px - 1px)` }}
        />

        <div
          id="slider-handle-value"
          style={{
            left: `calc(${answer}% - ${answer} / 100 * 50px + ${
              answer < 10 ? 23 : answer < 100 ? 18 : 13
            }px)`,
          }}
        >
          {answer}
        </div>
      </div>

      <div id="slider-label-container">
        <span className="slider-label label-left">
          {trans(language, "Completely")}
          <br />
          {trans(language, "disagree")}
        </span>
        <span className="slider-label label-right">
          {trans(language, "Completely")}
          <br />
          {trans(language, "agree")}
        </span>
      </div>

      <div id="next-btn-container">
        <button id="next-btn" onClick={handleClick} disabled={submitDisabled}>
          {trans(language, "Next")}
        </button>
      </div>
    </>
  );
};

export default Percentage;
