import React, {useState, useEffect} from 'react';
import VideoRecorder from 'react-video-recorder';
import styled from 'styled-components';

// components
import VideoPlayer from '../VideoPlayer';
import Loader from '../../../../components/Loader';

// services
import {api} from "../../../../services/api";

// hooks
import {useQuestion} from "../../../../hooks/useQuestion";

// styles
import './index.scss';

type Props = {
  saveAnswer: Function,
  submissionId: string,
}

const StyledVideoPlayer = styled(VideoPlayer)`
  width: 100%;
  margin-top: 48px
`
const isProd = (process.env.REACT_APP_API_BASE_URL as string).includes('/prod');


const VideoQuestion = ({
  saveAnswer,
  submissionId,
}: Props) => {
  const { question } = useQuestion();
  const [hasSeenQuestionsVideo, setHasSeenQuestionsVideo] = useState(!question.video_question_src);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setHasSeenQuestionsVideo(!question.video_question_src);
  }, [question.video_question_src])

  const handleSubmit = async (videoBlob: any) => {
    setIsSaving(true);
    const queryParams = new URLSearchParams({
      testId: question.test_id,
      submissionId: submissionId,
      ...(!isProd && {  env: 'dev' }),
    })

    await api.retryApi(api.createSignedS3VideoPartUrl,
        async (data) => {
          await api.uploadVideo(data.signedUrl, videoBlob);
          await saveAnswer(data.filename);

          setIsSaving(false);
        }, {
            queryParams: queryParams.toString()
        });
  }

  return <>
    {
      !hasSeenQuestionsVideo ? (
        <StyledVideoPlayer
          src={question.video_question_src}
          onEnded={() => setTimeout(() => setHasSeenQuestionsVideo(true), 500)}
        />
      ) : (
        <div id='question-text-container'>
          <p id="question-text" dangerouslySetInnerHTML={{__html: question.label}}></p>
        </div>
      )
    }

    {
      isSaving ? (
        <div id="question-container">
          <Loader />
        </div>
      ) : hasSeenQuestionsVideo ? (
        <div>
          <VideoRecorder
            timeLimit={Number(question.max_answering_time) * 1000}
            replayVideoAutoplayAndLoopOff={true}
            showReplayControls={false}
            onRecordingComplete={handleSubmit}
            isOnInitially={true}
            onTurnOnCamera={() => {
              const interval = setInterval(() => {
                const button = document.querySelector('button[data-qa="start-recording"]');

                if (button) {
                  (button as any).click?.();
                  clearInterval(interval);
                }
              }, 100);
            }}
          />
        </div>
      ) : null
    }
  </>
};

export default VideoQuestion;
