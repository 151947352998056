// services
import { trans } from '../../services/lang';

// hooks
import {useSettings} from "../../hooks/useSettings";

// styles
import './index.scss';

const QuestionnaireExpired = () => {
  const { language } = useSettings();

  return (
    <div id="questionnaire-completed-container">
      <h2>{trans(language,'Attention')}</h2>

      <p>
        {trans(language, 'You can only check your score once every 30 days')}
      </p>
    </div>
  );
};

export default QuestionnaireExpired;
