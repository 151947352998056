import React, { useState, useMemo, useEffect } from 'react';

// components
import Loader from './components/Loader';

// pages
import PersonalInfo from './pages/PersonalInfo';
import Questionnaire from './pages/Questionnaire';
import QuestionnaireExpired from './pages/QuestionnaireCompleted';

// hooks
import { useSettings } from "./hooks/useSettings";
import { usePermissions } from "./hooks/usePermissionsHook";
import { useQuestionnaire } from './hooks/useQuestionnaire';
//services

import { messageHost } from "./services/iframe";
// styles
import './App.scss';
import ErrorPanel from "./pages/Error";


const App = () => {
  const {
    isQuestionnaireCompleted,
    isQuestionnaireLoaded,
    isQuestionnaireExpired,
    isExpiredOnLoad,
    isAnonymised,
    errorMessage: qErrorMessage,
  } = useQuestionnaire();
  const { granted, permissionsResetRequired, denied, permissionsError } = usePermissions();
  const { isIframe, iframeSrc, iframeError } = useSettings()
  const [showPersonalInfo, setShowPersonalInfo] = useState(true);
  const hasError = useMemo(() => {
    return qErrorMessage || iframeError || (denied && permissionsError)
  }, [qErrorMessage, iframeError, permissionsError, denied]);

  useEffect(() => {
    if (isAnonymised && showPersonalInfo) {
      setShowPersonalInfo(false);
    }
  }, [isAnonymised, showPersonalInfo]);

  const isPersonalFormPageVisible = useMemo(() => (
      isQuestionnaireLoaded && showPersonalInfo && !isQuestionnaireCompleted
  ), [isQuestionnaireLoaded, showPersonalInfo, isQuestionnaireCompleted]);

  const isQuestionnairePageVisible = useMemo(() => (
      isQuestionnaireLoaded && !showPersonalInfo && !isQuestionnaireExpired
  ), [isQuestionnaireLoaded, showPersonalInfo, isQuestionnaireExpired]);

  const isQuestionnaireExpiredPageVisible = isQuestionnaireExpired;

  const onQuestionnaireExpiredOnLoad = () => {
    if (isIframe) {
      messageHost('flow-already-completed');
    }
  }
  const onQuestionnaireCompletion = () => {
    if (isIframe) {
      messageHost('flow-completed', iframeSrc);
    }
  }

  useEffect(() => {
    if (isExpiredOnLoad) {
      onQuestionnaireExpiredOnLoad();
    }
  }, [isExpiredOnLoad])


  const renderPage = () => {
    return <>
      {
          isPersonalFormPageVisible && (
              <PersonalInfo setShowPersonalInfo={setShowPersonalInfo}/>
          )
      }
      {
          isQuestionnairePageVisible && (
              <Questionnaire onCompletion={onQuestionnaireCompletion} />
          )
      }
      {
          isQuestionnaireExpiredPageVisible && (
              <QuestionnaireExpired />
          )
      }
    </>
  }

  if (!granted && !denied && !permissionsResetRequired) {
    return <Loader />
  }

  return (
      <div className="App">
        { !isQuestionnaireLoaded && !hasError && <Loader /> }
        { isQuestionnaireLoaded && !hasError && renderPage() }
        { hasError && <ErrorPanel message={qErrorMessage! || iframeError! || permissionsError!} />  }
      </div>
  );
};

export default App;
