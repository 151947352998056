import React from 'react';

// styles
import './index.scss';

type Props = {
    completed: number;
}

const ProgressBar = (props: Props) => {
  return (
    <div className='progress-bar-container'>
      <div className='progress-bar-filler' style={{'width': `${props.completed ?? 0}%`}} />
    </div>
  );
};

export default ProgressBar;
