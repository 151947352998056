export const toHoursMinutesSeconds = (totalSeconds: number) => {
  const totalMinutes = Math.floor(totalSeconds / 60);

  const seconds = totalSeconds % 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { h: hours, m: minutes, s: seconds };
}

export const minutesAndSecondsFormatter = (totalSeconds: number) => {
  const { m, s } = toHoursMinutesSeconds(totalSeconds);

  return `${m}:${s}`;
};

