import React, { useMemo } from 'react';

// services
import {trans} from '../../services/lang';

// hooks
import {useSettings} from '../../hooks/useSettings';

// styles
import './index.scss';
import { useQuestionnaire } from '../../hooks/useQuestionnaire';

type Props = {
  step: number,
}

export const ANNONYMISED_STATIC_STEPS = 1;
export const STATIC_STEPS = 2;

const MultiStepHeader = ({
  step,
}: Props) => {
  const { language, isAppEmbedded } = useSettings();
  const { questions, isAnonymised } = useQuestionnaire();

  const totalSteps = useMemo(() => {
    const questionsSteps = questions?.length || 1;
    const staticSteps = isAnonymised ? ANNONYMISED_STATIC_STEPS : STATIC_STEPS;

    return questionsSteps + staticSteps;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions?.length]);

  const currentStep = useMemo(() => {
    if (isAnonymised) {
      // the step is the actual step in the flow with all the static and dynamic pages
      // we need to subtract the hidden pages when we are in Anonymised mode
      return step - (STATIC_STEPS - ANNONYMISED_STATIC_STEPS);
    }

    return step;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  if (!isAppEmbedded) {
    return null;
  }

  return (
    <p id='multi-step-header'>
      { `${trans(language, 'Step')} ${currentStep}/${totalSteps}` }
    </p>
  );
}

export default MultiStepHeader;
