// services
import { trans } from '../../../../services/lang';

// hooks
import {useSettings} from "../../../../hooks/useSettings";

// styles
import './index.scss';

const NotFound = () => {
  const { language } = useSettings();

  return (
    <div id="questionnaire-not_found-container">
      <p>{trans(language, 'Questionnaire not found.')}</p>
    </div>
  );
};

export default NotFound;
