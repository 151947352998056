import React, { useContext, useEffect, useState } from 'react';
import { getBrowserType } from "../utils/browser";
import { trans } from "../services/lang";
import { useSettings } from "./useSettings";

interface Props {
    granted: boolean;
    denied: boolean;
    permissionsResetRequired: boolean;
    permissionsError: string | null;
    requestPermissions: Function;
}

const PermissionContext = React.createContext<Props | null>(null);

export const PermissionContextProvider = ({children}: any) => {
    const browserType = getBrowserType();
    const { language } = useSettings();
    const [granted, setGranted] = useState<boolean>(false);
    const [denied, setDenied] = useState<boolean>(false);
    const [permissionsResetRequired, setPermissionsResetRequired] = useState<boolean>(false);
    const [permissionsError, setPermissionsError] = useState<string | null>(null);

    useEffect(() => {
        requestPermissions();
    }, []);

    const requestPermissions = async () => {
        return new Promise(async (resolve, reject) => {
            navigator.mediaDevices.getUserMedia = navigator.mediaDevices.getUserMedia || (navigator as any).webkitGetUserMedia || (navigator as any).mozGetUserMedia;

            return navigator.mediaDevices
                .getUserMedia({
                    video: true,
                    audio: true,
                })
                .then(stream => {
                    if (browserType === 'Google Chrome') {
                        navigator.permissions
                            .query(({name: 'camera'} as unknown as PermissionDescriptor));
                    }

                })
                .then(newCameraPermission => {
                    if (browserType === 'Google Chrome') {
                        navigator.permissions
                        .query({name: 'microphone'} as unknown as PermissionDescriptor)
                        .then(newMicrophonePermission => {
                            setGranted(true)
                        });
                    } else {
                        setGranted(true);
                    }
                })
                .catch(err => {
                    // video already accessed by different app (win 10 only)
                    if (err.name === 'NotReadableError') {
                        const errorMessage = trans(
                            language,
                            `Please ensure that no other application is using your camera and microphone & you have granted access to your camera and microphone to your browser ({{browser}}) in your system settings. Then reload the page.`
                        );

                        setPermissionsError(errorMessage.replace('{{browser}}', browserType));
                        setDenied(true);
                    }
                    // in this case we need to stop the flow and make the user fully reset his
                    // permissions because he blocked them, chrome allows fine-grained control
                    else if (err.name === 'NotAllowedError' && err.message === 'Permission dismissed' ) {
                        return setPermissionsResetRequired(true);
                    // general fallback for most browsers
                    } else if (err.name === 'NotAllowedError') {
                        const errorMessage = trans(
                            language,
                            `Please reset your microphone and camera permissions in your browser settings`
                        );

                        setPermissionsError(`${errorMessage} (${browserType})`);
                        setDenied(true);
                    } else if (err.name === 'NotFoundError') {
                        const errorMessage = trans(
                          language,
                          `Please use a device with webcam and microphone support`
                        );

                        setPermissionsError(`${errorMessage} (${browserType})`);
                        setDenied(true);
                    } else {
                        setPermissionsError(`${err.message} (${err.name} - ${browserType})`);
                        setDenied(true);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
        });
    };

    return (
        <PermissionContext.Provider value={{
            granted,
            denied,
            permissionsResetRequired,
            permissionsError,
            requestPermissions,
        }}>
            {children}
        </PermissionContext.Provider>
    );
}

export function usePermissions() {
    const context = useContext(PermissionContext);

    if (!context) {
        throw new Error(
            'usePermission must be used within a PermissionContextProvider'
        );
    }

    return context;
}
