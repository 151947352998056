import React, { useEffect, useState, useMemo } from "react";
import { useDispatch } from 'react-redux';
import moment from 'moment';

// pages
import Intro from './pages/Intro';
import Expired from './pages/Expired';
import Completed from './pages/Completed';
import NotFound from './pages/NotFound';
import Question from './pages/Question';

// components
import Loader from '../../components/Loader';
import ProgressBar from '../../components/ProgressBar';
import TimeLeft from './components/TimeLeft';
import MultiStepHeader, { STATIC_STEPS } from "../../components/MultiStepHeader";

// services
import { api } from '../../services/api';

// actions
import {questionFulfilled, updateQuestionnaire} from '../../state/actions/questionsActions';

// hooks
import {useQuestionnaire} from '../../hooks/useQuestionnaire';
import {useQuestion} from '../../hooks/useQuestion';

// styles
import './index.scss';
import { usePermissions } from "../../hooks/usePermissionsHook";

interface Props {
  onCompletion?: Function,
}

const Questionnaire = ({ onCompletion }: Props) => {
  const {
    questionnaire,
    hasMoreQuestions,
    answeredQuestions,
    externalTestId,
    isQuestionnaireLoaded,
    hasTimedBoxedQuestions,
    isQuestionnaireCompleted,
    isQuestionnaireStarted,
    isQuestionnaireCreated,
    isQuestionnaireViewed,
    isQuestionnaireExpired,
    isQuestionnaireNotFound,
    submissionId,
  } = useQuestionnaire();
  const { granted, requestPermissions } = usePermissions();

  useEffect(() => {
    if (!granted) {
      requestPermissions();
    }
  }, [])

  useEffect(() => {
    if (isQuestionnaireCompleted) {
      onCompletion && onCompletion()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQuestionnaireCompleted]);

  const {
    question,
    isVideoRecordingQuestion,
  } = useQuestion();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  const hasTimeLeft = useMemo(() => timeLeft > 0 || isVideoRecordingQuestion, [timeLeft, isVideoRecordingQuestion]);

  const isQuestionPageVisible = useMemo(() => {
    return isQuestionnaireStarted && !isQuestionnaireCompleted;
  }, [isQuestionnaireStarted, isQuestionnaireCompleted]);

  const isIntroPageVisible = useMemo(() => {
    return (isQuestionnaireCreated || isQuestionnaireViewed) && !isQuestionnaireCompleted;
  }, [isQuestionnaireCompleted, isQuestionnaireCreated, isQuestionnaireViewed]);

  const step = useMemo(() => answeredQuestions?.length + STATIC_STEPS + 1, [answeredQuestions?.length])

  useEffect(() => {
    if (isQuestionnaireStarted && hasTimedBoxedQuestions) {
      const timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      // Clear timeout if the component is unmounted
      return () => clearTimeout(timer);
    }
  });

  const startTest = () => {
    api.startTest({ externalTestId }).then(() => {
      dispatch(
        updateQuestionnaire({ ...questionnaire, test_status: "started" })
      );
    });
  };

  const saveAnswer = (answer: any) => {
    const now = new Date();

    return api.saveAnswers(
      externalTestId,
      [{
        ...question,
        type: question.type,
        answers_shown_at: question.answers_shown_at,
        answer_value: answer,
        answer_status: "answered",
        updated_at: now.toISOString(),
      }]
    );
  };

  const completeTest = () => {
    return api.completeTest(
      externalTestId,
    );
  };

  const submitQuestionnaire = async () => {
    await completeTest();
    dispatch(updateQuestionnaire({ ...questionnaire, test_status: 'completed' }));
  };

  const submitAnswer = async (answer: any) => {
    const now = new Date();

    return api.retryApi(() => saveAnswer(answer), () => {
      dispatch(questionFulfilled({
        ...question,
        answers_shown_at: question.answers_shown_at,
        answer_value: answer,
        answer_status: "answered",
        updated_at: now.toISOString(),
      }));
    });
  };

  useEffect(() => {
    if (
      isQuestionnaireLoaded &&
      !hasMoreQuestions
    ) {
      setIsLoading(true);
      submitQuestionnaire()
        .then(() => {
          setIsLoading(false);
        });
    } else if (isQuestionnaireLoaded && hasMoreQuestions && !isVideoRecordingQuestion) {
      const seconds = moment(new Date().toISOString()).diff(
        moment(question.answers_shown_at),
        "seconds"
      );
      setTimeLeft(Math.max(seconds > 30 ? 0 : 30 - seconds, 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMoreQuestions]);

  if (isLoading || !granted) {
    return (
      <div id="question-container">
        <Loader />
      </div>
    );
  }

  return (
    <div id="question-container">
      {
        isIntroPageVisible && (
          <>
            <MultiStepHeader step={2} />

            <Intro
              startTest={startTest}
            />
          </>
        )
      }

      {
        isQuestionPageVisible && (
          <>
            <MultiStepHeader step={step} />

            <ProgressBar
              completed={questionnaire.progress}
            />

            <Question submissionId={submissionId} saveAnswer={submitAnswer} />

            {
              !isVideoRecordingQuestion && (
                <TimeLeft seconds={timeLeft} />
              )
            }
          </>
        )
      }

      {
        isQuestionnaireCompleted && (
          <Completed />
        )
      }

      {
        (isQuestionnaireExpired && !hasTimeLeft) && (
          <Expired />
        )
      }

      {
        isQuestionnaireNotFound && (
          <NotFound />
        )
      }
    </div>
  );
};

export default Questionnaire;
