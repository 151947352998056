import React, {useState} from 'react';

// services
import {trans} from '../../../../services/lang';

// hooks
import {useSettings} from "../../../../hooks/useSettings";
import {useQuestion} from "../../../../hooks/useQuestion";

// styles
import './index.scss';

type Props = {
  saveAnswer: Function,
}

const MultipleChoice = ({
  saveAnswer,
}: Props) => {
  const { language } = useSettings();
  const { question } = useQuestion();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const handleClick = async (e: any) => {
    setSubmitDisabled(true);
    await saveAnswer(e.target.value);
    setSubmitDisabled(false);
  }

  return (
    <>
      <div id='question-text-container'>
        <p id="question-text">
          {trans(language, 'How familiar you are with')} {question.label}
        </p>
      </div>

      <div id='answer-button-container'>
        <button
          className='answer-button'
          onClick={handleClick}
          value="2"
          disabled={submitDisabled}
        >
          {trans(language, 'I have heard of it many times')}
        </button>

        <button
          className='answer-button'
          onClick={handleClick}
          value="1"
          disabled={submitDisabled}
        >
          {trans(language, 'I have heard of it sometimes')}
        </button>

        <button
          className='answer-button'
          onClick={handleClick}
          value="0"
          disabled={submitDisabled}
        >
          {trans(language, 'I have never heard of it')}
        </button>
      </div>
    </>
  );
};

export default MultipleChoice;
