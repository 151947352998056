import {useState} from 'react';

// services
import {trans} from '../../../../services/lang';

// hooks
import {useSettings} from "../../../../hooks/useSettings";
import {useQuestion} from "../../../../hooks/useQuestion";

// styles
import './index.scss';

type Props = {
  saveAnswer: Function,
}

const Boolean = ({
  saveAnswer
}: Props) => {
  const { language } = useSettings();
  const { question } = useQuestion();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const handleClick = async (e: any) => {
    setSubmitDisabled(true);

    await saveAnswer(e.target.value);

    setSubmitDisabled(false);
  }

  return (
    <>
      <div id='question-text-container'>
        <p id="question-text" dangerouslySetInnerHTML={{__html: question.label}}></p>
      </div>

      <div id='boolean_answer-button-container'>
        <button
          className='answer-button'
          onClick={handleClick}
          value="1"
          disabled={submitDisabled}
        >
          {trans(language, 'True')}
        </button>

        <button
          className='answer-button'
          onClick={handleClick}
          value="0"
          disabled={submitDisabled}
        >
          {trans(language, 'False')}
        </button>
      </div>
    </>
  );
};

export default Boolean;
