import React from 'react';

// components
import Percentage from '../../components/Percentage';
import Boolean from '../../components/Boolean';
import MultipleChoice from '../../components/MultipleChoice';
import VideoQuestion from '../../components/VideoQuestion';

// hooks
import {useQuestion} from "../../../../hooks/useQuestion";

const Question = ({
  saveAnswer,
  submissionId,
}: any) => {
  const {
    isPercentageQuestion,
    isBooleanQuestion,
    isMultipleChoiceQuestion,
    isVideoRecordingQuestion,
  } = useQuestion();

  return (
    <>
      {
        isPercentageQuestion && (
          <Percentage saveAnswer={saveAnswer} />
        )
      }

      {
        isBooleanQuestion && (
          <Boolean saveAnswer={saveAnswer} />
        )
      }

      {
        isMultipleChoiceQuestion && (
          <MultipleChoice saveAnswer={saveAnswer} />
        )
      }

      {
        isVideoRecordingQuestion && (
          <VideoQuestion submissionId={submissionId} saveAnswer={saveAnswer} />
        )
      }
    </>
  );
};

export default Question;
