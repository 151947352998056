// services
import { trans } from '../../../../services/lang';

// hooks
import {useSettings} from "../../../../hooks/useSettings";

// styles
import './index.scss';

const Completed = () => {
  const { language } = useSettings();

  return (
    <div id="questionnaire-completed-container">
      <p>
        {trans(language, 'Thank you for answering, we will be in touch soon.')}
      </p>
    </div>
  );
};

export default Completed;
